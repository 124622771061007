<template>
  <div class="modal-wrapper">
    <div class="modal-pay">
      <div class="modal-title">
        <h2>{{ $t('front.cash.nav3') }}</h2>
        <a class="close-btn" @click="$emit('close')"><img src="@/assets/img/icon_cancel.svg" /></a>
      </div>
      <div class="modal-content">
        <ul class="modal-info">
          <li>
            <span>{{ $t('front.common.memId') }} : </span>
            <span>{{selectMem.memId}}</span>
          </li>
          <li>
            <span>{{ $t('front.common.nickName') }} : </span>
            <span>{{selectMem.memNick}}</span>
          </li>
          <li>
            <span>{{ $t('front.common.cashStatus') }} : </span>
            <span>{{selectMem.cashAmt}}</span>
          </li>
          <li>
            <span>{{ $t('front.common.moneyInProcess') }} : </span>
            <span class="inputWrap">
              <input id="cashInput" type="text" v-model="newCash">
              <!-- <label for="cashInput"></label> -->
            </span>
          </li>
          <li class="mbtnWrap">
            <a @click="setMoney(1000)">1,000</a>
            <a @click="setMoney(5000)">5,000</a>
            <a @click="setMoney(10000)">10,000</a>
            <a @click="setMoney(50000)">50,000</a>
            <a @click="setMoney(100000)">100,000</a>
            <a @click="setMoney(1000000)">1,000,000</a>
            <a @click="setMoney(5000000)">5,000,000</a>
          </li>
        </ul>
        <div class="modal-btns">
          <a @click="setCash('in')">{{ $t('front.give.give') }}</a>
          <a @click="setCash('out')">{{ $t('front.give.back') }}</a>
        </div>
      </div>
    </div>
  </div>
  <transition name="fade">
    <TMmodal v-show="TMOpen" :isOpen="TMOpen" :type="userType" @close="onCloseTM" :userData="selectMem"/>
  </transition>
</template>

<script>
import { thousand } from '@/libs/utils'
import { memCashInOut } from '@/api/give'
import TMmodal from '@/components/common/TotalMoney.vue'

export default {
  name: 'paymodal',
  components: { TMmodal },
  watch: {
    newCash () {
      const parts = this.newCash.split('.')
      const v = parts[0].replace(/\D/g, '')
      const dec = parts[1]
      // const calcNum = Number((dec !== undefined ? v + '.' + dec : v))
      let n = new Intl.NumberFormat('en-EN').format(v)
      n = dec !== undefined ? n + '.' + dec : n
      this.newCash = n
    }
  },
  methods: {
    thousand,
    async onCloseTM (state) {
      if (state !== 'fail') {
        await this.setCash('out')
      }
      this.TMOpen = false
    },
    setMoney (value) {
      let money = Number(this.newCash.replace(/,/g, ''))
      if (value) {
        money += value
      } else {
        money = value
      }
      this.newCash = thousand(money)
    },
    async setCash (type) {
      if (!this.newCash || this.newCash === '0') {
        alert('금액을 입력해주세요.')
      } else {
        this.emitter.emit('Loading', true)
        const cash = Number(this.newCash.replace(/,/g, ''))
        if (type === 'in') {
          const cfval = confirm('지급처리하시겠습니까?')
          if (cfval) {
            const req = {
              memId: this.selectMem.memId,
              inAmt: cash,
              memo: '',
              type: '',
              inBonusYn: 'N'
            }
            const res = await memCashInOut(req, '-3')
            const result = res.data
            if (result.resultCode === '0') {
              this.emitter.emit('ChangePay')
              this.emitter.emit('Loading', false)
              alert('지급완료')
              this.resultCash = Number(this.selectMem.cashAmt.replace(/,/, '')) + cash
              this.$emit('close', this.resultCash, this.selectIdx)
            } else {
              this.emitter.emit('Loading', false)
              alert('지급실패, 다시 시도해주세요.')
            }
          } else {
            this.emitter.emit('Loading', false)
          }
        } else {
          const cfval = confirm('회수처리하시겠습니까?')
          if (cfval) {
            const req = {
              memId: this.selectMem.memId,
              outAmt: cash,
              memo: '',
              type: '',
              bonusRollingYn: 'N'
            }
            console.log(req)
            const res = await memCashInOut(req, '3')
            const result = res.data
            console.log(result)
            if (result.resultCode === '0') {
              this.emitter.emit('ChangePay')
              this.emitter.emit('Loading', false)
              alert('회수 완료')
              this.resultCash = Number(this.selectMem.cashAmt.replace(/,/, '')) - cash
              this.$emit('close', this.resultCash, this.selectIdx)
            } else {
              if (result.resultCode === 'C702') {
                this.emitter.emit('Loading', false)
                alert('회수할 금액이 유저 보유금보다 많을 수 없습니다.')
              } else if (result.resultCode === 'C099') {
                this.emitter.emit('Loading', false)
                alert(result.resultMessage)
              } else {
                this.emitter.emit('Loading', false)
                alert('회수실패, 다시 시도해주세요.')
              }
            }
          } else {
            this.emitter.emit('Loading', false)
          }
        }
        this.newCash = '0'
        // this.resultCash = 0
      }
    }
  },
  data () {
    return {
      newCash: '0',
      resultCash: 0,
      TMOpen: false,
      userType: 'partner'
    }
  },
  created () {
    if (this.type) {
      this.userType = this.type
    }
  },
  props: [
    'selectMem',
    'selectIdx',
    'type'
  ]
}
</script>

<style scoped>
  .modal-wrapper {
    justify-content: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background: rgba(0, 0, 0, .4196078431372549);
  }
  .modal-pay {
    width: 385px;
    min-width: 385px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #c1c1c1;
    box-shadow: 6px 6px 6px 0 rgba(0, 0, 0, .16);
    padding: 15px 25px;
    background: #fafafa;
    box-sizing: border-box;
  }
  .modal-title {
    color: #646464;
    font-size: 13px;
    padding-bottom: 11px;
    border-bottom: 2px solid #959595;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .modal-title h2 {
    letter-spacing: -.39px;
    font-weight: 800;
  }
  .modal-title .close-btn {
    display: flex;
    align-items: center;
    width: 20px;
    height: 20px;
    color: #646464;
    cursor: pointer;
  }
  .modal-title .close-btn img {
    width: 100%;
  }
  .modal-content {
    padding-top: 10px;
    color: #343434;
    font-size: 12px;
  }
  .modal-info {
    flex-direction: column;
    gap: 10px;
  }
  .modal-info, .modal-info li {
    display: flex;
  }
  .modal-info li {
    align-items: center;
    gap: 5px;
  }
  .modal-btns, .modal-btns a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-btns {
    gap: 10px;
    margin-top: 18px;
  }
  .modal-btns a {
    width: 90px;
    height: 26px;
    border-radius: 2px;
    border: 1px solid #646464;
    box-sizing: border-box;
    background-color: #fafafa;
    font-size: 13px;
    color: #343434;
    cursor: pointer;
  }
  .inputWrap {
    position: relative;
  }
  #cashInput {
    width: 110px;
    height: 20px;
    color: transparent;
    background-color: transparent;
    border: 1px solid #000;
    color: #000;
    border-radius: 0;
    position: relative;
    z-index: 1;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  #cashInput+label {
    font-size: 12px;
    background: #fff;
    color: #000;
    height: 20px;
    padding: 0 6px;
    box-sizing: border-box;
    width: 110px;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .mbtnWrap {flex-wrap: wrap;}
  .mbtnWrap a {width: calc(25% - 4px);height: 25px;border-radius: 4px;background: #e9f0f6;border: 1px solid #646464;box-sizing: border-box;display: flex;align-items: center;justify-content: center;}
</style>
